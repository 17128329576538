.drawer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.actionButton {
    padding: 10px 24px;
    text-transform: initial;
    gap: 16px;
    border-radius: 8px;
    box-shadow: none;
}

.phoneInput {
    :global {
        svg {
            height: 20px;
        }
    }

    input {
        html[dir='rtl'] & {
            direction: rtl;
            text-align: left;
        }
    }
}

.closeButton {
    color: #9b9eab;
    padding: 0;
}
