.main {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 6px 0;
    margin: 8px 0 0;

    .field {
        display: flex;
        align-items: center;
        justify-content: center;

        .label {
            padding: 2px 6px;
            font-weight: bold;
            color: #9b9eab;
            font-size: 0.9em;
        }

        .value {
            padding: 2px 6px;
            flex: 1 1;
        }

        .phoneField {
            padding: 2px 6px;
            display: flex;
            align-items: center;
            > svg {
                padding-inline-start: 2px;
            }
        }
    }
}
